import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/forge/docs.wild.plus/node_modules/gatsby-theme-docz/src/base/Layout.js";
import lerna from './lerna.json';
import action from '!!raw-loader!./action.yml';
import { Code } from "gatsby-theme-docz/src/components/Code";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "lerna-release"
    }}>{`Lerna Release`}</h1>
    <p>{`A Github Action for release monorepo packages under registry.wild.plus.`}</p>
    <h2 {...{
      "id": "behavior"
    }}>{`Behavior`}</h2>
    <p>{`On every push on the `}<strong parentName="p">{`main`}</strong>{` branch:`}</p>
    <ul>
      <li parentName="ul">{`Packages that have changes will be published under registry.wild.plus.  `}</li>
      <li parentName="ul">{`Based on the commit history, a changelog will be generated on each package folder.`}</li>
      <li parentName="ul">{`A Github release will be created for each published package.`}</li>
    </ul>
    <h2 {...{
      "id": "installation"
    }}>{`Installation`}</h2>
    <ul>
      <li parentName="ul">{`Install lerna, launching `}<inlineCode parentName="li">{`yarn add lerna -D -W`}</inlineCode>{` from the root of the repository`}</li>
      <li parentName="ul">{`Create a new `}<inlineCode parentName="li">{`release`}</inlineCode>{` script in your `}<inlineCode parentName="li">{`package.json`}</inlineCode>{`:`}</li>
    </ul>
    <Code className="json" mdxType="Code">
      {`{
  ...
  "scripts": {
    ...
    "release": "lerna publish --yes"
  }
}`}
    </Code>
    <ul>
      <li parentName="ul">{`Then add this to a `}<inlineCode parentName="li">{`lerna.json`}</inlineCode>{` file:`}</li>
    </ul>
    <Code className="json" mdxType="Code">
  {JSON.stringify(lerna, null, 4)}
    </Code>
    <ul>
      <li parentName="ul">{`Then add this to a `}<inlineCode parentName="li">{`.github/workflows/action.yml`}</inlineCode>{` file:`}</li>
    </ul>
    <Code className="yaml" mdxType="Code">
  {action}
    </Code>
    <ul>
      <li parentName="ul">{`Create a secret clicking on `}<strong parentName="li">{`Settings > Secrets > New Repository Secrets`}</strong>{`:`}</li>
    </ul>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`NPM_TOKEN`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Search `}<inlineCode parentName="td">{`registry.wild.plus`}</inlineCode>{` on 1Password`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "publish-under-registrynpmjsorg"
    }}>{`Publish under registry.npmjs.org`}</h2>
    <p>{`In some cases, you might need to publish directly under registry.npmjs.org.`}<br parentName="p"></br>{`
`}{`Be sure to follow these steps:`}</p>
    <ul>
      <li parentName="ul">{`Change the `}<inlineCode parentName="li">{`release`}</inlineCode>{` script adding the `}<inlineCode parentName="li">{`no-verify-access`}</inlineCode>{` flag (read `}<a parentName="li" {...{
          "href": "https://github.com/lerna/lerna/issues/2788"
        }}>{`here`}</a>{` why):`}
        <Code className="json" mdxType="Code">
          {`{
  ...
  "scripts": {
    ...
    "release": "lerna publish --yes --no-verify-access"
  }
}`}
        </Code>
      </li>
      <li parentName="ul">{`Drop the `}<inlineCode parentName="li">{`commands.publish.registry`}</inlineCode>{` field in `}<inlineCode parentName="li">{`lerna.json`}</inlineCode></li>
      <li parentName="ul">{`Replace `}<inlineCode parentName="li">{`registry.wild.plus`}</inlineCode>{` as `}<inlineCode parentName="li">{`registry.npmjs.org`}</inlineCode>{` in `}<inlineCode parentName="li">{`.github/workflows/action.yml`}</inlineCode></li>
      <li parentName="ul">{`Search `}<inlineCode parentName="li">{`NPM wild user`}</inlineCode>{` on 1Password and replace the NPM_TOKEN value.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      